<script lang="ts" setup>
import { useField } from 'vee-validate';

// Porops
const props = defineProps<{
  label: string;
  name: string;
  items: {
    label: string;
    key: string;
  }[];
  required?: boolean;
  asButton?: boolean;
  hideDetails?: boolean;
  disabled?: boolean;
}>();

// Data
const { value, errorMessage } = useField(props.name);

// Methods
const isChecked = (key: string) => {
  return value.value === key;
};

const setValue = (key: string) => {
  if (props.disabled) return;
  if (value.value === key) {
    value.value = '';
  } else {
    value.value = key;
  }
};
</script>
<template>
  <div ref="inputContainer" class="w-full" :class="[hideDetails ? '' : 'mb-[16px]']">
    <label
      v-if="label"
      :class="[errorMessage ? 'text-red-300' : 'text-gray-600', disabled ? '!text-gray-400' : '']"
      class="font-medium text-xs mb-[6px] block text-left"
    >
      {{ label }}
      <span v-if="required" class="text-red-500">*</span>
    </label>
    <div class="flex flex-wrap gap-2 relative">
      <div
        v-for="item in items"
        :key="`input-checkbox-${item.label}`"
        class="flex select-none items-center border h-[32px] border-gray-200 rounded-md text-sm px-4 relative ring-offset-0 text-gray-600"
        :class="[
          isChecked(item.key) ? 'bg-primary-100 text-primary-500 border-primary-500 hover:ring-1 hover:ring-primary-100' : ' ',
          disabled
            ? '!text-gray-400 !border-gray-200 !bg-gray-50 cursor-not-allowed'
            : 'hover:border-gray-300 hover:ring-1 hover:ring-gray-100 cursor-pointer',
        ]"
        @click="setValue(item.key)"
      >
        {{ item.label }}
      </div>
      <span class="absolute right-0 top-[34px] text-red-500 text-xs">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>
