<script setup lang="ts">
import { useI18n } from 'vue-i18n';

// Composables
const { t } = useI18n();

// Stores
const areaService = areaStore();
const siteService = siteStore();
const props = defineProps<{
  areaId?: number;
}>();

// Computed
const areas = computed(() => {
  const items = areaService.areas
    .filter((area) => {
      if (props.areaId) {
        return area.id !== props.areaId;
      }
      return true;
    })
    .map((area) => ({
      key: area.id,
      label: area.name,
    }));

  items.unshift({
    key: 0,
    label: t('area.no_parent_zone'),
  });

  return items;
});
const sites = computed(() => {
  return siteService.sites.map((site) => ({
    key: site.id,
    label: site.name,
  }));
});
const areaTypes = computed(() => {
  return areaService.areaTypes.map((areaType) => ({
    key: areaType.value,
    label: t(`area.area_types.${areaType.value}`),
  }));
});
</script>
<template>
  <ui-card :title="$t('area.area_details')">
    <div class="grid grid-cols-2 gap-x-4">
      <ui-form-input-text
        name="name"
        required
        autocomplete="off"
        :placeholder="$t('area.placeholder_area_name')"
        :label="$t('area.name')"
      />
      <ui-form-input-select
        name="area_type"
        required
        :placeholder="$t('area.select_area_type')"
        :label="$t('area.area_type')"
        :items="areaTypes"
      />
      <ui-form-input-select
        name="parent_id"
        :placeholder="$t('area.select_parent')"
        :label="$t('area.parent')"
        :disabled="!userStore().isAdmin"
        :items="areas"
      />
      <ui-form-input-select
        name="site_id"
        required
        :placeholder="$t('area.select_site')"
        :disabled="true"
        :label="$t('area.site')"
        :items="sites"
      />
    </div>
  </ui-card>
</template>
