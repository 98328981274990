<script setup lang="ts">
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';

// Composables
const { flux } = useAreaForm();
const { t } = useI18n();

const props = defineProps<{
  areaType: string;
}>();

// Data
const { value: productionUnitIsDifferent } = useField('production_unit_is_different');
const { value: flux_type } = useField('flux_type');
const { value: unitId } = useField('unit_id');
const { value: secondaryUnitId } = useField('secondary_unit_id');

// Computed
const units = computed(() => {
  const unitsList = unitsStore().units.list.filter((unit) => unit.type === 'prod');
  return unitsList.map((unit) => ({
    key: unit.id,
    label: unit.symbol,
  }));
});

const getSecondUnitTitle = computed(() => {
  switch (flux_type.value) {
    case 'daily_production':
      return t('configuration.daily_production_unit');
    case 'weekly_production':
      return t('configuration.weekly_production_unit');
    case 'monthly_production':
      return t('configuration.monthly_production_unit');
    default:
      return t('configuration.production_unit');
  }
});

// Lifecycle
watch(
  () => unitId.value,
  () => {
    if (flux_type.value === 'fabrication_order' && productionUnitIsDifferent.value === 'false') {
      secondaryUnitId.value = unitId.value;
    }
  },
);

watch(
  () => productionUnitIsDifferent.value,
  () => {
    if (productionUnitIsDifferent.value === 'false') {
      secondaryUnitId.value = unitId.value;
    }
  },
);

watch(
  () => props.areaType,
  () => {
    if (props.areaType === 'utility') {
      flux_type.value = '';
    }
  },
);

watch(
  () => flux_type.value,
  () => {
    if (flux_type.value === 'fabrication_order' || flux_type.value === '') {
      productionUnitIsDifferent.value = 'false';
    }
  },
);
</script>
<template>
  <ui-card :title="$t('configuration.flux')">
    <div class="grid grid-cols-2 gap-x-4">
      <ui-form-input-radio
        name="flux_type"
        :disabled="areaType === 'utility'"
        required
        :placeholder="$t('configuration.select_flux')"
        :label="$t('configuration.flux_type')"
        :items="flux"
      />
      <ui-form-input-select
        name="unit_id"
        :required="flux_type !== ''"
        :placeholder="$t('area.select_unit')"
        :label="$t('configuration.unit')"
        :items="units"
      />
      <ui-form-input-radio
        v-show="flux_type === 'fabrication_order'"
        name="production_unit_is_different"
        required
        :label="$t('configuration.production_unit_is_different')"
        :items="[
          { key: 'true', label: $t('global.yes') },
          { key: 'false', label: $t('global.no') },
        ]"
      />
      <ui-form-input-select
        v-show="flux_type === 'fabrication_order'"
        name="secondary_unit_id"
        required
        :disabled="productionUnitIsDifferent === 'false'"
        :placeholder="$t('area.select_unit')"
        :label="getSecondUnitTitle"
        :items="units"
      />
    </div>
  </ui-card>
</template>
