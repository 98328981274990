import { useI18n } from 'vue-i18n';

export const useAreaForm = () => {
  const { t } = useI18n();

  const flux = computed(() => [
    {
      key: 'fabrication_order',
      label: t('configuration.fabrication_order'),
    },
    {
      key: 'daily_production',
      label: t('configuration.daily'),
    },
    {
      key: 'weekly_production',
      label: t('configuration.weekly'),
    },
    {
      key: 'monthly_production',
      label: t('configuration.monthly'),
    },
  ]);

  return {
    flux,
  };
};
