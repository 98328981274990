<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import * as yup from 'yup';

// Components
import ManageAreaInformation from '@/app-modules/configuration/components/area/card/ManageInformation.vue';
import ManageFeed from '~/app-modules/configuration/components/area/card/ManageFeed.vue';

import type { Area } from '~/types/area';
import { Production_Reporting_Frequencies_Enum } from '~/types/graphql/graphql';

// Composables
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

// Stores
const { showToast } = toastStore();
const areaService = areaStore();

// Data
const loading = ref(false);

// Form
const { handleSubmit, meta, resetForm, values } = useForm({
  validationSchema: yup.object({
    name: yup.string().required(t('global.required_field')),
    area_type: yup.string().required(t('global.required_field')),
    parent_id: yup.number().required(t('global.required_field')),
    site_id: yup.number().required(t('global.required_field')),

    // Production reporting
    flux_type: yup.string().nullable(),
    unit_id: yup.number().when('$exit', {
      is: () => values.flux_type !== '',
      then: (schema) => schema.required(t('global.required_field')),
      otherwise: (schema) => schema.nullable(),
    }),
    production_unit_is_different: yup.string().when('$exit', {
      is: () => values.flux_type === 'fabrication_order',
      then: (schema) => schema.required(t('global.required_field')),
      otherwise: (schema) => schema.nullable(),
    }),
    secondary_unit_id: yup.number().when('$exit', {
      is: () => values.production_unit_is_different === 'true',
      then: (schema) => schema.required(t('global.required_field')),
      otherwise: (schema) => schema.nullable(),
    }),
  }),
});

// Computed
const area = computed(() => {
  return areaService.areas.find((area) => area.id === Number(route.params.id));
});
const title = computed(() => {
  if (route?.name === 'area-manage') {
    return `${t('area.manage_area')}: ${area.value.name}`;
  }
  return t('area.add_area');
});

// Methods
const submit = handleSubmit(async (values) => {
  loading.value = true;

  const objectToSend: Partial<Area> = {
    name: values.name,
    parent_id: values.parent_id,
    area_type: values.area_type,
    site_id: values.site_id,
    is_fabrication_order_reporting_enabled: values.flux_type === 'fabrication_order',
    is_production_reporting_enabled: (() => {
      if (['daily_production', 'weekly_production', 'monthly_production'].includes(values.flux_type)) return true;
      return false;
    })(),
    fabrication_order_unit_id: values.flux_type === 'fabrication_order' ? values.unit_id : null,
    production_unit_id: (() => {
      if (values.flux_type === 'fabrication_order' && values.production_unit_is_different === 'true') {
        return values.secondary_unit_id;
      }
      if (['daily_production', 'weekly_production', 'monthly_production'].includes(values.flux_type)) {
        return values.unit_id;
      }
      if (values.flux_type === 'fabrication_order' && values.production_unit_is_different === 'false') {
        return values.unit_id;
      }
      if (values.flux_type === '' && values.unit_id) {
        return values.unit_id;
      }
      return null;
    })(),
    production_reporting_frequency: (() => {
      switch (values.flux_type) {
        case 'daily_production':
          return Production_Reporting_Frequencies_Enum.Day;
        case 'weekly_production':
          return Production_Reporting_Frequencies_Enum.Week;
        case 'monthly_production':
          return Production_Reporting_Frequencies_Enum.Month;
        default:
          return null;
      }
    })(),
  };

  if (route.name === 'area-manage') {
    try {
      await areaService.updateArea({
        areaId: Number(route.params.id),
        form: objectToSend,
      });
      showToast({
        type: 'success',
        title: t('global.success'),
        message: t('area.update_area_success'),
      });
    } catch (error) {
      showToast({
        type: 'error',
        title: t('global.error'),
        message: t('area.update_area_error'),
      });
      throw error;
    }
  } else {
    try {
      await areaService.createArea({
        form: objectToSend,
      });
      showToast({
        type: 'success',
        title: t('global.success'),
        message: t('area.create_area_success'),
      });
    } catch (error) {
      showToast({
        type: 'error',
        title: t('global.error'),
        message: t('area.create_area_error'),
      });
      throw error;
    }
  }
  router.push('/configuration/areas');
  loading.value = false;
});

// Lifecycle
onMounted(() => {
  if (area.value) {
    const is_fabrication_order_reporting_enabled = area.value.is_fabrication_order_reporting_enabled ?? false;
    const is_production_reporting_enabled = area.value.is_production_reporting_enabled ?? false;

    const getFluxType = () => {
      if (is_fabrication_order_reporting_enabled) return 'fabrication_order';
      if (is_production_reporting_enabled) {
        if (area.value.production_reporting_frequency === 'day') return 'daily_production';
        if (area.value.production_reporting_frequency === 'week') return 'weekly_production';
        if (area.value.production_reporting_frequency === 'month') return 'monthly_production';
      }
      return '';
    };

    const getUnit = () => {
      if (is_fabrication_order_reporting_enabled) return area.value.fabrication_order_unit_id;
      if (is_production_reporting_enabled) return area.value.production_unit_id;
      return area.value.production_unit_id;
    };

    const getProductionUnitIsDifferent = () => {
      if (area.value.is_production_reporting_enabled) return 'false';
      else if (!area.value.is_fabrication_order_reporting_enabled && !area.value.is_production_reporting_enabled) return 'false';
      else if (area.value.fabrication_order_unit_id === area.value.production_unit_id) return 'true';
      return 'false';
    };

    resetForm({
      values: {
        name: area.value.name,
        parent_id: area.value.parent_id ?? 0,
        area_type: area.value.area_type,
        site_id: area.value.site_id,
        flux_type: getFluxType(),
        unit_id: getUnit(),
        production_unit_is_different: getProductionUnitIsDifferent(),
        secondary_unit_id: area.value.production_unit_id ?? null,
      },
    });
  } else {
    resetForm({
      values: {
        parent_id: 0,
        site_id: globalStore().getSelectedSite[0],
        flux_type: null,
        unit_id: null,
        production_unit_is_different: 'false',
      },
    });
  }
});
</script>
<template>
  <ui-page :title="title" :breadcrumb="[{ name: $t('global.areas'), url: '/configuration/areas' }]" :form-dirty="meta.dirty">
    <template #header-actions>
      <ui-button left-icon="Save" @click="submit">{{ $t('global.save') }}</ui-button>
    </template>

    <div v-if="values" class="flex flex-col gap-4">
      <manage-area-information :area-id="Number(route.params.id)" />
      <manage-feed v-if="values?.area_type !== 'office'" :area-type="values.area_type" :area-id="Number(route.params.id)" />
    </div>
  </ui-page>
</template>
<style scoped>
.card-enter-active,
.card-leave-active {
  transition: all 0.2s ease;
}
.card-enter-from,
.card-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
